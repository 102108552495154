Vue.transition('slideIn', {
    enterClass: 'slideInRight',
    leaveClass: 'slideOutRight'
});

Vue.transition('fadeIn', {
    enterClass: 'fadeIn',
    leaveClass: 'fadeOut'
});

Vue.transition('slideInLeft', {
    enterClass: 'slideInLeft',
    leaveClass: 'slideOutLeft'
});

// services logic
new Vue({
    el: '#app',
    data: {
        showMobileMenu: false,
        serviceSelected: false,
        formTimesSubmitted: 0,
        formMessage: '',
        submitting: false,
        message: '',
        phone: '',
        email: '',
        timeOut: '',
        elements: '',
        showSlide: false,
        slides: {
            selected: '/img/image-banner-00.jpg',
            list: [
                '/img/image-banner-00.jpg',
                '/img/image-banner-01.jpg',
                '/img/image-banner-02.jpg',
                '/img/image-banner-03.jpg',
                '/img/image-banner-04.jpg'
            ]
        },
        services: {
            selected: '',
            list: [
                {
                    title: 'Satisfaction Guaranteed',
                    image: '/img/image-services-trans-satisfactionguaranteed.png',
                    text: '',
                    imageLg: '',
                    cssClass: '',
                    cssClassLg: ''
                },
                {
                    title: 'Licensed & Insured',
                    image: '/img/image-services-trans-licensedinsured.png',
                    text: '',
                    imageLg: '',
                    cssClass: '',
                    cssClassLg: ''
                },
                {
                    title: 'Detail Oriented',
                    image: '/img/image-services-trans-detailoriented.png',
                    text: '',
                    imageLg: '',
                    cssClass: '',
                    cssClassLg: ''
                },
                {
                    title: 'Confidentiality',
                    image: '/img/image-services-trans-confidentiality.png',
                    text: '',
                    imageLg: '',
                    cssClass: '',
                    cssClassLg: ''
                },
                {
                    title: 'Reliable 24/7',
                    cssClass: 'sm-clock',
                    image: '/img/clock/120/body.png',
                    text: '',
                    imageLg: '/img/clock/220/body.png',
                    cssClassLg: 'lg-clock'
                },
                {
                    title: 'Eco-Friendly',
                    image: '/img/image-services-trans-ecofriendly.png',
                    text: '',
                    imageLg: '',
                    cssClass: '',
                    cssClassLg: ''
                }
            ]
        }
    },
    methods: {
        selectService: function (service, e, i) {
            var self = this;
            var target = $(e.target).closest('.click-slider__images__image');

            if (target.hasClass('selected')) {
                return;
            }

            $('.click-slider__images__image').removeClass('selected');
            target.addClass('selected');

            clearTimeout(this.timeOut);
            self.serviceSelected = false;
            this.timeOut = setTimeout(function () {
                self.services.selected = i;
                self.serviceSelected = true;
            }, 500);
        },
        onSubmit: function () {
            if (this.submitting) {
                return;
            }

            var emailRegex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
            var phoneRegex = /^(\+?1)?([\s\(\-]?){1,2}(\d{3})([\)\s\-\.]?){1,2}(\d{3})([\-\s\.])?(\d{4})/i;
            var email = document.getElementById('email');
            var message = document.getElementById('message');

            function passTest(regex, name) {
                var el = document.getElementById(name);
                var value = this[name] ? this[name].trim() : null;

                var pass;
                if (name === 'phone' && !value) {
                    pass = true;
                } else {
                    pass = regex.test(value);
                }
                if (!pass) {
                    el.parentNode.classList.add('is-invalid');
                }
                return pass;
            }

            function passLength(lgth, name) {
                var el = document.getElementById(name);
                var value = this[name] ? this[name].trim() : null;
                var pass = value.length >= lgth;
                if (!pass) {
                    el.parentNode.classList.add('is-invalid');
                }
                return pass;
            }


            if (++this.formTimesSubmitted === 1) {
                email.setAttribute('required', true);
                message.setAttribute('required', true);
            }

            if (
                passTest.call(this, emailRegex, 'email') &&
                passLength.call(this, 10, 'message') &&
                passTest.call(this, phoneRegex, 'phone')
            ) {
                var emailVal = this.email.trim();
                var phoneVal = this.phone.trim();
                var messageVal = this.message.trim();
                var self = this;
                self.submitting = true;

                fetch('/send-email', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        email: emailVal,
                        phone: phoneVal,
                        message: messageVal
                    })
                }).then(function (res) {
                    if (res.status !== 200) {
                        self.formMessage = 'Looks like there was a problem. Status Code: ' + res.status;
                        self.submitting = false;
                        return;
                    }

                    return res.json();
                }).then(function (data) {
                    self.formMessage = data.message;

                    if (data.sent) {
                        self.email = '';
                        self.phone = '';
                        self.message = '';
                    }

                    setTimeout(function () {
                        self.submitting = false;
                        self.formMessage = '';

                    }, 5000);


                }).catch(function (err) {
                    console.log('err', err);
                    self.submitting = false;
                })
            }
        }
    },
    ready: function () {
        this.elements = document.getElementsByClassName('click-slider__images__image');

        if (this.elements.length) {
            this.elements[0].classList.add('selected');

            this.serviceSelected = true;
            this.services.selected = 0;
        }
    }
});

// header animation
var headerElement = document.querySelector('header');
var headroom = new Headroom(headerElement, {
    offset: 99,
    tolerance: 20,

    classes: {
        initial: "animated",
        pinned: "slideInDown",
        unpinned: "slideOutUp"
    }
});
headroom.init();

// scroll to
smoothScroll.init({
    offset: 0
});

// butterfly animation
var butterflyContainer = document.getElementById('butterfly');
if (butterflyContainer) {
    var viewPortHeight = document.documentElement.clientHeight || window.innerHeight;
    var butterflyContainerOffset = butterflyContainer.offsetTop;

    function butterflyContainerCenterOffset(viewPortOffset) {
        return viewPortOffset + (viewPortHeight / 2) - butterflyContainerOffset;
    }

    window.addEventListener('scroll', function () {
        var offset = butterflyContainerCenterOffset(document.body.scrollTop);

        if (offset <= 0) {
            butterflyContainer.classList.remove('up');
        } else {
            butterflyContainer.classList.add('up');
        }
    });
}

// banner slideshow
var slidesContainer = document.getElementsByClassName('slides')[0];

if (slidesContainer) {
    var slides = document.getElementsByClassName('slide');
    var index = 0;
    var interval = setInterval(function () {
        Array.prototype.forEach.call(slides, function (s, i) {
            s.classList.remove('active');
        });
        if (index === slides.length - 1) {
            index = 0;
        }
        slides[++index].classList.add('active');
    }, 6000);
}
// clock animation
(function ($) {
    $(document).ready(function () {
        var $smHour = $('.sm-hour');
        var $smMin = $('.sm-min');
        var $smSec = $('.sm-sec');

        var $lgHour = $('.lg-hour');
        var $lgMin = $('.lg-min');
        var $lgSec = $('.lg-sec');

        setInterval(function () {
            var seconds = new Date().getSeconds();
            var sdegree = seconds * 6;
            var srotate = "rotate(" + sdegree + "deg)";

            $lgSec.css({"-moz-transform": srotate, "-webkit-transform": srotate, "transform": srotate});
            $smSec.css({"-moz-transform": srotate, "-webkit-transform": srotate, "transform": srotate});
        }, 1000);

        setInterval(function () {
            var hours = new Date().getHours();
            var mins = new Date().getMinutes();
            var hdegree = hours * 30 + (mins / 2);
            var hrotate = "rotate(" + hdegree + "deg)";

            $smHour.css({"-moz-transform": hrotate, "-webkit-transform": hrotate, "transform": hrotate});
            $lgHour.css({"-moz-transform": hrotate, "-webkit-transform": hrotate, "transform": hrotate});
        }, 1000);

        setInterval(function () {
            var mins = new Date().getMinutes();
            var mdegree = mins * 6;
            var mrotate = "rotate(" + mdegree + "deg)";

            $smMin.css({"-moz-transform": mrotate, "-webkit-transform": mrotate, "transform": mrotate});
            $lgMin.css({"-moz-transform": mrotate, "-webkit-transform": mrotate, "transform": mrotate});
        }, 1000);
    });
}(jQuery));
